import React from 'react';
import product from './Hero_BloodOrange_SeltzerCan.png'; // Tell webpack this JS file uses this image
import product2 from './mealprep.png'; // Tell webpack this JS file uses this image
import product3 from './pumkincooler.png'; // Tell webpack this JS file uses this image
import './App.css';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Cookies from 'universal-cookie';
import { withRouter } from "react-router-dom"
import { useSpring, animated } from 'react-spring'

const calc = (x, y) => [-(y - window.innerHeight / 2) / 200, (x - window.innerWidth / 2) / 200, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const cookies = new Cookies();

function ProductHolder() {
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 1, tension: 500, friction: 100 } }))
    return (
        <animated.div
            class="Product-Holder"
            onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
            onMouseLeave={() => set({ xys: [0, 0, 1] })}
            style={{ transform: props.xys.interpolate(trans) }}>
            <Image src={product} alt="Blood Orange Seltzer Can" className="img-fluid Product-Image no-gutters"/>
        </animated.div>
    )
}

class Upload extends React.Component {

    constructor(props) {
        super(props);
        if (cookies.get('myAccess') !== 'True') {
            this.props.history.push("/");
        }
    }

    render() {
        return (
            <div className="App">
                    <Container fluid>
                        <Row className="justify-content-center">
                            <Col xs={0} xl={3} className="align-self-center d-none d-xl-flex" >
                                <div className="float-right">
                                    <ProductHolder />
                                </div>
                            </Col>
                            <Col xs={12} xl={6} className="vertical-center">
                                <Container>
                                    <Row>
                                        <Col xs={12}>
                                            <p className='App-Body-Med-Bold-White'>Your Entry Has <br/> Been Received!</p>
                                            <br/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <p className='App-Body-Med-Bold-White'> You have unlocked your Additional Chance To </p>
                                            <br/>
                                            <p className='App-Body-Large-Yellow'> WIN $500... </p>
                                            <br/>
                                            <p className='App-Body-Med-Regular-White'> Thank you for Shareing your best <br/> PLatinum Seltzer Pic </p>
                                            <br/><br/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <Container>
                                                <Row className="justify-content-center">
                                                    <Col xs={4} sm={4} md={5} lg={4} className="d-xl-none align-self-center justify-content-end no-gutters">
                                                        <div className="float-right">
                                                            <ProductHolder />
                                                        </div>
                                                    </Col>
                                                    <Col xs={8} sm={8} md={5} lg={4} xl={12} className="mt-2 d-flex flex-column align-self-center align-content-start no-gutters">
                                                        <div>
                                                            <Image src={product2} alt="Product" className="img-fluid"/>
                                                            <br/>
                                                        </div>
                                                        <div>
                                                            <Image src={product3} alt="Product" className="img-fluid"/>
                                                            <br/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col xs={12}  className="d-xs-flex d-xl-none align-self-center justify-content-center">
                                            <p className='App-MFTN-Large-Yellow'>Made for <br/> the Night</p>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col xs={12}>
                                            <p className='App-Disclaimer'>BUD LIGHT™ PLATINUM™ SELTZER SWEEPSTAKES</p>
                                            <br/>
                                            <p className='App-Disclaimer'>No Purchase Necessary.  Open to NC & VA residents 21+.  Sweepstakes begins 10/30/20 and ends 11/9/20.  See Official Rules for prize and complete details.  Message & data rates may apply.  Void where prohibited. Enjoy Responsibly
                                            </p>
                                            <br/>
                                            <p className='App-Disclaimer'>© 2020 Anheuser-Busch, Bud Light™ Platinum™ Seltzer, Blood Orange, Citrus, Wild Berry, IRC Beer (Ale in TX), St. Louis, MO 63118</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="mt-2 d-flex align-self-center align-content-start btn-link">
                                            <a href="https://platinumseltzer.com/pdf/rules.pdf" target="_blank" role="button"
                                               className="linkbtn text-decoration-none">Official Rules</a>
                                            <a href="https://www.budlight.com/en/privacy-policy.html" target="_blank"
                                               role="button" className="linkbtn text-decoration-none">Privacy Policy</a>
                                            <a href="https://www.budlight.com/en/terms-and-conditions.html.html"
                                               target="_blank" role="button" className="linkbtn text-decoration-none">Terms
                                                Of Use</a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col xs={0} xl={3} className="d-none d-xl-flex  align-self-center justify-content-center">
                                <p className='App-Body-Large-Yellow'>Made for <br/> the Night</p>
                            </Col>
                        </Row>
                    </Container>
            </div>
        );
    }
}

export default withRouter(Upload);
